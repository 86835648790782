import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { FieldTextInput, FieldSelect, PrimaryButton } from '../../components';
import getCountryCodes, { getDeliverableCountryCodes } from '../../translations/countryCodes';
import { required } from '../../util/validators';
import css from './ProfileAddress.module.css';

function Step(props) {
  const intl = useIntl();
  const t = intl.formatMessage;
  const { fieldId, values, fieldIndex } = props;

  const isSubmitDisabled =
    !values?.addresses?.[fieldIndex]?.name ||
    !values?.addresses?.[fieldIndex]?.companyName ||
    !values?.addresses?.[fieldIndex]?.street ||
    !values?.addresses?.[fieldIndex]?.streetNumber ||
    !values?.addresses?.[fieldIndex]?.postalcode ||
    !values?.addresses?.[fieldIndex]?.city ||
    !values?.addresses?.[fieldIndex]?.country;

  return (
    <div>
      <FieldTextInput
        className={css.field}
        type="hidden"
        id={`${fieldId}.id`}
        name={`${fieldId}.id`}
        readOnly
      />
      <FieldTextInput
        className={css.field}
        type="text"
        id={`${fieldId}.name`}
        name={`${fieldId}.name`}
        label={t({
          id: 'ProfileAddress.nameLabel',
        })}
        placeholder={t({
          id: 'ProfileAddress.namePlaceholder',
        })}
        validate={required(
          t({
            id: 'ProfileAddress.nameRequired',
          })
        )}
        customErrorClass={css.fieldError}
      />
      <FieldTextInput
        className={css.field}
        type="text"
        id={`${fieldId}.companyName`}
        name={`${fieldId}.companyName`}
        label={t({
          id: 'ProfileAddress.companyNameLabel',
        })}
        placeholder={t({
          id: 'ProfileAddress.companyNamePlaceholder',
        })}
        validate={required(
          t({
            id: 'ProfileAddress.companyNameRequired',
          })
        )}
        customErrorClass={css.fieldError}
      />
      <div className={css.addressStreetContainer}>
        <FieldTextInput
          className={css.addressStreet}
          type="text"
          id={`${fieldId}.street`}
          name={`${fieldId}.street`}
          label={t({
            id: 'ProfileAddress.streetLabel',
          })}
          placeholder={t({
            id: 'ProfileAddress.streetPlaceholder',
          })}
          validate={required(
            t({
              id: 'ProfileAddress.streetRequired',
            })
          )}
          customErrorClass={css.fieldError}
        />
        <FieldTextInput
          className={css.addressStreetNumber}
          type="text"
          id={`${fieldId}.streetNumber`}
          name={`${fieldId}.streetNumber`}
          label={t({
            id: 'ProfileAddress.streetNumberLabel',
          })}
          placeholder={t({
            id: 'ProfileAddress.streetNumberPlaceholder',
          })}
          validate={required(
            t({
              id: 'ProfileAddress.streetNumberRequired',
            })
          )}
          customErrorClass={css.fieldError}
        />
      </div>
      <div className={css.addressPostalcodeContainer}>
        <FieldTextInput
          className={css.addressPostalcode}
          type="text"
          id={`${fieldId}.postalcode`}
          name={`${fieldId}.postalcode`}
          label={t({
            id: 'ProfileAddress.postalcodeLabel',
          })}
          placeholder={t({
            id: 'ProfileAddress.postalcodePlaceholder',
          })}
          validate={required(
            t({
              id: 'ProfileAddress.postalcodeRequired',
            })
          )}
          customErrorClass={css.fieldError}
        />
        <FieldTextInput
          className={css.addressCity}
          type="text"
          id={`${fieldId}.city`}
          name={`${fieldId}.city`}
          label={t({
            id: 'ProfileAddress.cityLabel',
          })}
          placeholder={t({
            id: 'ProfileAddress.cityPlaceholder',
          })}
          validate={required(
            t({
              id: 'ProfileAddress.cityRequired',
            })
          )}
          customErrorClass={css.fieldError}
        />
      </div>
      <FieldSelect
        className={css.field}
        id={`${fieldId}.country`}
        name={`${fieldId}.country`}
        label={t({
          id: 'ProfileAddress.countryLabel',
        })}
        placeholder={t({
          id: 'ProfileAddress.countryPlaceholder',
        })}
        validate={required(
          t({
            id: 'ProfileAddress.countryRequired',
          })
        )}
        customErrorClass={css.fieldError}
      >
        <option value="" disabled>
          {t({ id: 'ProfileAddress.countryPlaceholder' })}
        </option>
        {getDeliverableCountryCodes(intl.locale).map(item => (
          <option key={item.code} value={item.code}>
            {item.name}
          </option>
        ))}
      </FieldSelect>
      <div className={css.buttonSegment}>
        {/* <PrimaryButton className={css.backBtn} type="button" disabled={props.currentStep === 1} onClick={props.previousStep}>
          Back
        </PrimaryButton> */}
        <PrimaryButton
          className={css.nextBtn}
          type="button"
          disabled={isSubmitDisabled}
          onClick={props.nextStep}
        >
          <FormattedMessage id="ProfileAddress.Wizard.Next" />
        </PrimaryButton>
      </div>
    </div>
  );
}

export default Step;
