import * as React from 'react';

const LogoIcon = props => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 4333 915"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <g transform="matrix(1,0,0,1,-314,-366)">
      <g id="GuardUp_Logo_auf_weiss_RGB">
        <g transform="matrix(8.24198,0,0,8.24198,511.825,1186.17)">
          <path
            d="M0,-88.033C-2.76,-88.033 -5.348,-86.959 -7.287,-85.01C-12.939,-79.357 -13.975,-70.647 -9.806,-63.829L-4.396,-54.987L-3.301,-56.473C3.232,-65.339 13.689,-70.632 24.67,-70.632L37.323,-70.632C48.292,-70.632 58.744,-65.344 65.283,-56.486L66.379,-55.002L71.78,-63.829C75.957,-70.649 74.921,-79.36 69.261,-85.011C67.312,-86.959 64.724,-88.033 61.974,-88.033L0,-88.033ZM24.352,-59.211C16.387,-59.211 8.9,-54.956 4.812,-48.105L2.314,-44.022L4.803,-39.953C9,-33.086 16.609,-28.82 24.661,-28.82L37.611,-28.82C45.605,-28.82 53.104,-33.094 57.182,-39.975L59.655,-44.016L57.17,-48.078C52.974,-54.945 45.365,-59.211 37.314,-59.211L24.352,-59.211ZM-10.032,-23.824C-13.944,-17.06 -12.843,-8.566 -7.286,-3.02C-5.339,-1.072 -2.751,0.002 0,0.002L61.974,0.002C64.735,0.002 67.322,-1.072 69.261,-3.02C74.907,-8.666 75.943,-17.377 71.78,-24.202L66.369,-33.043L65.274,-31.558C58.742,-22.692 48.285,-17.398 37.304,-17.398L24.661,-17.398C13.678,-17.401 3.226,-22.696 -3.307,-31.562L-4.402,-33.048L-10.047,-23.824L-10.032,-23.824ZM-1.321,11.422C-6.337,11.359 -11.042,9.377 -14.593,5.825L-15.765,4.654C-24.956,-4.537 -26.552,-19.165 -19.56,-30.129L-11.071,-44.009L-19.552,-57.871C-26.484,-69.203 -24.763,-83.686 -15.368,-93.092L-14.603,-93.856C-10.993,-97.466 -6.191,-99.455 -1.082,-99.455L63.057,-99.455C68.166,-99.455 72.967,-97.466 76.577,-93.856L77.748,-92.684C86.94,-83.493 88.536,-68.865 81.544,-57.902L73.054,-44.031L81.536,-30.17C88.471,-18.822 86.75,-4.338 77.352,5.051L76.588,5.815C72.976,9.426 68.175,11.414 63.066,11.414L-1.321,11.414L-1.321,11.422Z"
            style={{
              fill: 'rgb(255,9,83)',
            }}
          />
        </g>
        <g transform="matrix(8.24198,0,0,8.24198,4416.76,915.344)">
          <path
            d="M0,13.923C9.421,13.923 16.54,6.909 16.54,-2.826C16.54,-12.562 9.421,-19.576 0,-19.576C-9.422,-19.576 -16.541,-12.562 -16.541,-2.826C-16.541,6.909 -9.422,13.923 0,13.923M1.779,-30.359C16.016,-30.359 27.846,-18.32 27.846,-2.826C27.846,12.772 16.016,24.706 1.779,24.706C-6.386,24.706 -12.563,21.356 -16.541,15.912L-16.541,44.282L-27.847,44.282L-27.847,-28.998L-16.541,-28.998L-16.541,-21.46C-12.563,-27.009 -6.386,-30.359 1.779,-30.359"
            style={{
              fill: 'rgb(255,9,83)',
              fillRule: 'nonzero',
            }}
          />
        </g>
        <g transform="matrix(8.24198,0,0,8.24198,3893.66,503.784)">
          <path
            d="M0,74.64C-16.121,74.64 -27.741,64.8 -27.741,48.992L-27.741,0L-15.703,0L-15.703,48.259C-15.703,56.844 -10.782,62.915 0,62.915C10.783,62.915 15.703,56.844 15.703,48.259L15.703,0L27.741,0L27.741,48.992C27.741,64.8 16.017,74.64 0,74.64"
            style={{
              fill: 'rgb(255,9,83)',
              fillRule: 'nonzero',
            }}
          />
        </g>
        <g transform="matrix(8.24198,0,0,8.24198,3370.03,592.653)">
          <path
            d="M0,53.075C9.422,53.075 16.541,46.061 16.541,36.325C16.541,26.59 9.422,19.576 0,19.576C-9.421,19.576 -16.54,26.59 -16.54,36.325C-16.54,46.061 -9.421,53.075 0,53.075M16.541,-10.783L27.847,-10.783L27.847,62.497L16.541,62.497L16.541,54.959C12.563,60.508 6.386,63.858 -1.779,63.858C-16.016,63.858 -27.846,51.819 -27.846,36.325C-27.846,20.727 -16.016,8.793 -1.779,8.793C6.386,8.793 12.563,12.143 16.541,17.587L16.541,-10.783Z"
            style={{
              fill: 'rgb(0,0,0)',
              fillRule: 'nonzero',
            }}
          />
        </g>
        <g transform="matrix(8.24198,0,0,8.24198,2991.81,1027.51)">
          <path
            d="M0,-33.813C3.141,-40.932 9.526,-43.549 16.122,-43.549L16.122,-31.301C8.689,-31.824 0,-28.369 0,-16.645L0,9.736L-11.306,9.736L-11.306,-42.607L0,-42.607L0,-33.813Z"
            style={{
              fill: 'rgb(0,0,0)',
              fillRule: 'nonzero',
            }}
          />
        </g>
        <g transform="matrix(8.24198,0,0,8.24198,2603.65,753.999)">
          <path
            d="M0,33.499C9.422,33.499 16.54,26.485 16.54,16.75C16.54,7.014 9.422,0 0,0C-9.421,0 -16.54,7.014 -16.54,16.75C-16.54,26.485 -9.421,33.499 0,33.499M16.54,-9.422L27.846,-9.422L27.846,42.921L16.54,42.921L16.54,35.383C12.562,40.932 6.386,44.282 -1.78,44.282C-16.017,44.282 -27.846,32.243 -27.846,16.75C-27.846,1.151 -16.017,-10.783 -1.78,-10.783C6.386,-10.783 12.562,-7.433 16.54,-1.989L16.54,-9.422Z"
            style={{
              fill: 'rgb(0,0,0)',
              fillRule: 'nonzero',
            }}
          />
        </g>
        <g transform="matrix(8.24198,0,0,8.24198,2230.67,1118.96)">
          <path
            d="M0,-53.703L11.306,-53.703L11.306,-1.361L0,-1.361L0,-8.06C-3.454,-2.617 -9.107,0 -16.226,0C-27.951,0 -36.325,-7.956 -36.325,-21.565L-36.325,-53.703L-25.019,-53.703L-25.019,-22.716C-25.019,-14.76 -20.413,-10.573 -13.295,-10.573C-5.862,-10.573 0,-14.97 0,-25.648L0,-53.703Z"
            style={{
              fill: 'rgb(0,0,0)',
              fillRule: 'nonzero',
            }}
          />
        </g>
        <g transform="matrix(8.24198,0,0,8.24198,1884.68,816.124)">
          <path
            d="M0,-2.513L0,2.512C0,22.507 -14.446,36.744 -35.906,36.744C-58.728,36.744 -74.954,19.785 -74.954,-1.257C-74.954,-22.298 -58.518,-39.257 -36.535,-39.257C-22.611,-39.257 -10.782,-32.243 -4.606,-21.88L-14.969,-15.913C-18.738,-22.717 -26.904,-27.533 -36.535,-27.533C-52.028,-27.533 -62.915,-16.122 -62.915,-1.152C-62.915,13.608 -52.237,25.019 -35.802,25.019C-22.507,25.019 -14.341,18.11 -12.143,8.27L-36.639,8.27L-36.639,-2.513L0,-2.513Z"
            style={{
              fill: 'rgb(0,0,0)',
              fillRule: 'nonzero',
            }}
          />
        </g>
      </g>
    </g>
  </svg>
);

export default LogoIcon;
