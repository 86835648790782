import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import { isLessor } from '../../util/data';

import css from './UserNav.module.css';

const UserNav = props => {
  const { className, rootClassName, currentPage, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);

  const tabs = [];

  if (isLessor(currentUser)) {
    tabs.push({
      text: <FormattedMessage id="UserNav.yourListings" />,
      selected: currentPage === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    });
  } else {
    tabs.push({
      text: <FormattedMessage id="UserNav.favoriteListings" />,
      selected: currentPage === 'FavoriteListingsPage',
      linkProps: {
        name: 'FavoriteListingsPage',
      },
    });
  }

  tabs.push({
    text: <FormattedMessage id="UserNav.accountSettings" />,
    selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
    disabled: false,
    linkProps: {
      name: 'ContactDetailsPage',
    },
  });

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

export default compose(connect(mapStateToProps))(UserNav);
