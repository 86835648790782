import { createSlice } from '@reduxjs/toolkit';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const ASSET_NAME = 'terms-of-service';

const initialState = {
  cart: [],
};

const CartPageSlice = createSlice({
  name: 'CartPage',
  initialState,
  reducers: {
    setCart(state, action) {
      state.cart = action.payload;
    },
    setSufficientSeats(state, action) {
      const { index, sufficientSeats } = action.payload;
      state.cart[index].sufficientSeats = sufficientSeats;
    },
  },
  selectors: {
    selectCart: state => state.cart,
  },
});

export const loadData = (params, search) => dispatch => {
  const pageAsset = { termsOfService: `content/pages/${ASSET_NAME}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};

export const { setCart, setSufficientSeats } = CartPageSlice.actions;
export const { selectCart } = CartPageSlice.selectors;
export default CartPageSlice.reducer;
