import React from 'react';
import { useIntl } from 'react-intl';

import { FieldTextInput } from '../../components';
import { validateVAT } from '../../util/api';
import { minLength } from '../../util/validators';

const EU_COUNTRY_CODES = [
  'AT',
  'DE'
];


const FieldVATInput = props => {
  const intl = useIntl();
  const { customErrorText, ...restProps } = props;

  return (
    <FieldTextInput
      {...restProps}
      type="text"
      customErrorText={customErrorText}
      // validate={(value, allValues, meta) => validateVATNumber(value, intl, handleVatVerified)}
    />
  );
};

export default FieldVATInput;
