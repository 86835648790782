import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { FieldTextInput, FieldSelect, PrimaryButton, FieldCheckbox } from '..';
import getCountryCodes, { getDeliverableCountryCodes } from '../../translations/countryCodes';
import { required } from '../../util/validators';
import css from './ProfileAddress.module.css';

function Step2(props) {
  const intl = useIntl();
  const t = intl.formatMessage;
  const { fieldId, values, fieldIndex } = props;
  
  const isSubmitDisabled =
    !values?.addresses?.[fieldIndex]?.protectionGoal ||
    !values?.addresses?.[fieldIndex]?.specialDeliveryFeatures ||
    !values?.addresses?.[fieldIndex]?.uploadingFacility ||
    !values?.addresses?.[fieldIndex]?.powerSourceDistance 

  return (
    <div>
      <FieldTextInput
        className={css.field}
        type="text"
        id={`${fieldId}.protectionGoal`}
        name={`${fieldId}.protectionGoal`}
        label={t({
          id: 'ProfileAddress.protectionGoalLabel',
        })}
        placeholder={t({
          id: 'ProfileAddress.protectionGoalPlaceholder',
        })}
        validate={required(
          t({
            id: 'ProfileAddress.protectionGoalRequired',
          })
        )}
        customErrorClass={css.fieldError}
      />
      <FieldCheckbox
        rootClassName={css.field}
        id={`${fieldId}.checkNetworkCoverage`}
        name={`${fieldId}.checkNetworkCoverage`}
        label={t({
          id: 'ProfileAddress.checkNetworkCoverageLabel',
        })}
        value={`${fieldId}.checkNetworkCoverage`}
      />
      <FieldTextInput
        className={css.field}
        type="text"
        id={`${fieldId}.specialDeliveryFeatures`}
        name={`${fieldId}.specialDeliveryFeatures`}
        label={t({
          id: 'ProfileAddress.specialDeliveryFeaturesLabel',
        })}
        placeholder={t({
          id: 'ProfileAddress.specialDeliveryFeaturesPlaceholder',
        })}
        validate={required(
          t({
            id: 'ProfileAddress.specialDeliveryFeaturesRequired',
          })
        )}
        customErrorClass={css.fieldError}
      />
      <FieldTextInput
        className={css.field}
        type="text"
        id={`${fieldId}.uploadingFacility`}
        name={`${fieldId}.uploadingFacility`}
        label={t({
          id: 'ProfileAddress.uploadingFacilityLabel',
        })}
        placeholder={t({
          id: 'ProfileAddress.uploadingFacilityPlaceholder',
        })}
        validate={required(
          t({
            id: 'ProfileAddress.uploadingFacilityRequired',
          })
        )}
        customErrorClass={css.fieldError}
      />
      <FieldSelect
        className={css.field}
        id={`${fieldId}.powerSourceDistance`}
        name={`${fieldId}.powerSourceDistance`}
        label={t({
          id: 'ProfileAddress.powerSourceDistanceLabel',
        })}
        placeholder={t({
          id: 'ProfileAddress.powerSourceDistancePlaceholder',
        })}
        validate={required(
          t({
            id: 'ProfileAddress.powerSourceDistanceRequired',
          })
        )}
        customErrorClass={css.fieldError}
      >
        <option value="" disabled>
          {t({ id: 'ProfileAddress.powerSourceDistancePlaceholder' })}
        </option>
        <option key="1-50" value="1To50">
          {t({ id: 'ProfileAddress.powerSourceDistance1To50' })}
        </option>
        <option key="50-100" value="51To100">
          {t({ id: 'ProfileAddress.powerSourceDistance51To100' })}
        </option>
        <option key=">100" value="MoreThan100">
          {t({ id: 'ProfileAddress.powerSourceDistanceMoreThan100' })}
        </option>
        <option key="not-specified" value="NotSpecified">
          {t({ id: 'ProfileAddress.powerSourceDistanceNotSpecified' })}
        </option>
      </FieldSelect>

      <FieldCheckbox
        className={css.field}
        id={`${fieldId}.externalSecurityNeeded`}
        name={`${fieldId}.externalSecurityNeeded`}
        label={t({
          id: 'ProfileAddress.externalSecurityNeededLabel',
        })}
        value={`${fieldId}.externalSecurityNeeded`}
      />
      <div className={css.buttonSegment}>
        <PrimaryButton
          className={css.backBtn}
          type="button"
          disabled={props.currentStep === 1}
          onClick={props.previousStep}
        >
          <FormattedMessage id="ProfileAddress.Wizard.Back" />
        </PrimaryButton>
        <PrimaryButton
          className={css.nextBtn}
          type="button"
          disabled={isSubmitDisabled}
          onClick={props.nextStep}
        >
          <FormattedMessage id="ProfileAddress.Wizard.Next" />
        </PrimaryButton>
      </div>
    </div>
  );
}

export default Step2;
