/*
 * Renders a set of options with selected and non-selected values.
 *
 * The corresponding component when selecting the values is
 * FieldCheckboxGroup.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import includes from 'lodash/includes';

import css from './PropertyGroup.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const checkSelected = (options, selectedOptions) => {
  return options.map(option => ({
    key: option.key,
    label: option.label,
    isSelected: includes(selectedOptions, option.key),
    text: option.text,
    closeText: option.closeText,
  }));
};

const Item = props => {
  const { label, text, isSelected, closeText, Icon } = props;
  const labelClass = isSelected ? css.selectedLabel : css.notSelectedLabel;
  return (
    <li className={css.item}>
      <span className={css.iconWrapper}>
        {Icon ? <Icon /> : <FontAwesomeIcon icon={faCheck} fontSize={20} color="rgb(255, 9, 83)" />}
      </span>
      <div className={css.labelWrapper}>
        <span className={labelClass}>{label}</span>
      </div>
      {text && (
        <p style={{ marginLeft: closeText ? '5px' : 'auto' }} className={css.text}>
          {text}
        </p>
      )}
    </li>
  );
};

const PropertyGroup = props => {
  const {
    rootClassName,
    className,
    id,
    options,
    selectedOptions,
    twoColumns,
    showUnselectedOptions,
    icons,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(classes, css.twoColumns) : classes;

  const checked = showUnselectedOptions
    ? checkSelected(options, selectedOptions)
    : checkSelected(options, selectedOptions).filter(o => o.isSelected);
  return (
    <ul className={listClasses}>
      {checked.map(option => (
        <Item
          Icon={icons?.[option.key] ?? null}
          key={`${id}.${option.key}`}
          label={option.label}
          isSelected={option.isSelected}
          text={option.text}
          closeText={option.closeText}
        />
      ))}
    </ul>
  );
};

PropertyGroup.defaultProps = {
  rootClassName: null,
  className: null,
  selectedOptions: [],
  twoColumns: false,
};

const { arrayOf, bool, node, shape, string } = PropTypes;

PropertyGroup.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ),
  selectedOptions: arrayOf(string),
  twoColumns: bool,
};

export default PropertyGroup;
