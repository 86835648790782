import React, { useState } from 'react';
import { string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import { FormattedMessage } from '../../util/reactIntl';

import { H3, Form, PrimaryButton, H4 } from '../../components';

import { useUpdateProfileMutation } from '../../ducks/UserApi';

import Address from './Address';

import css from './ProfileAddress.module.css';
import StepWizard from 'react-step-wizard';
import Step1 from './Step1';
import Nav from './Nav';
import Step2 from './Step2';
import Step3 from './Step3';

const ProfileAddress = props => {
  const [
    updateProfile,
    { isLoading: updateInProgress, error: updateError },
  ] = useUpdateProfileMutation();
  const [submittedValues, setSubmittedValues] = useState({});
  const [currentStep, setCurrentStep] = useState(1);

  const { addresses, fieldIndex, onUpdateSuccess } = props;

  const initialValues = {
    addresses,
  };

  const submit = values => {
    const { addresses: addressesDirty } = values;
    const updatedAddresses = [...addresses];
    updatedAddresses[fieldIndex] = addressesDirty[fieldIndex];
    if (updatedAddresses[fieldIndex]?.externalSecurityNeeded?.length) {
      updatedAddresses[fieldIndex].externalSecurityNeededBool = true;
    } else {
      updatedAddresses[fieldIndex].externalSecurityNeededBool = false;
    }
    if (updatedAddresses[fieldIndex]?.checkNetworkCoverage?.length) {
      updatedAddresses[fieldIndex].checkNetworkCoverageBool = true;
    } else {
      updatedAddresses[fieldIndex].checkNetworkCoverageBool = false;
    }
    const updateValues = {
      protectedData: {
        addresses: updatedAddresses,
      },
    };

    return updateProfile(updateValues).then(response => {
      if (typeof onUpdateSuccess === 'function') {
        onUpdateSuccess();
      }
    });
  };

  const fieldId = 'addresses';
  const existingAddress = !!addresses[fieldIndex]?.name;

  const stepNames = {
    1: 'ProfileAddress.step1Title',
    2: 'ProfileAddress.step2Title',
    3: 'ProfileAddress.step3Title',
  };

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      onSubmit={submit}
      render={fieldRenderProps => {
        const {
          className,
          invalid,
          pristine,
          rootClassName,
          handleSubmit,
          values,
          form,
        } = fieldRenderProps;
        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = updateInProgress;
        const submittedOnce = Object.keys(submittedValues).length > 0;
        const pristineSinceLastSubmit = submittedOnce && isEqual(values, submittedValues);
        const submitDisabled = invalid || pristine || pristineSinceLastSubmit || submitInProgress;
        return (
          <Form
            className={classes}
            onSubmit={e => {
              // const submitValues = values.addresses.map((valuesX, valuesIndex) => {
              //   if (valuesIndex === fieldIndex) {
              //     const updatedValuesX = { ...valuesX };
              //     if (updatedValuesX?.externalSecurityNeeded?.length) {
              //       updatedValuesX.externalSecurityNeededBool = true;
              //       form.change('externalSecurityNeededBool', true);
              //     }
              //     if (updatedValuesX?.checkNetworkCoverage?.length) {
              //       updatedValuesX.checkNetworkCoverageBool = true;
              //       form.change('checkNetworkCoverageBool', true);
              //     }
              //     return updatedValuesX;
              //   }else{
              //     return valuesX;
              //   }
              // });
              // setSubmittedValues(submitValues);
              setSubmittedValues(values);
              handleSubmit(e);
            }}
          >
            <H4>
              {/* <FormattedMessage
                id={`ProfileAddress.${existingAddress ? 'titleEdit' : 'titleAdd'}`}
              /> */}
              <FormattedMessage id={stepNames[currentStep]} />
            </H4>
            <StepWizard
              nav={<Nav />}
              initialStep={1}
              onStepChange={steps => setCurrentStep(steps.activeStep)}
              transitions={{
                enterRight: '',
                enterLeft: '',
                exitRight: '',
                exitLeft: '',
              }}
            >
              <Step1 fieldId={`${fieldId}.${fieldIndex}`} values={values} fieldIndex={fieldIndex} />
              <Step2 fieldId={`${fieldId}.${fieldIndex}`} values={values} fieldIndex={fieldIndex} />
              <Step3
                fieldId={`${fieldId}.${fieldIndex}`}
                submitInProgress={submitInProgress}
                submitDisabled={submitDisabled}
                pristineSinceLastSubmit={pristineSinceLastSubmit}
              />
            </StepWizard>
            {/* <Address fieldId={`${fieldId}.${fieldIndex}`} /> */}
            {updateError && (
              <div className={css.error}>
                <FormattedMessage id="ProfileAddress.updateProfileFailed" />
              </div>
            )}
          </Form>
        );
      }}
    />
  );
};

ProfileAddress.defaultProps = {
  rootClassName: null,
  className: null,
};

ProfileAddress.propTypes = {
  rootClassName: string,
  className: string,
};

export default ProfileAddress;
