import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';

import { splitDateRangeIntoIntervals, getDateRanges } from '../util/dates';
import { forEach } from 'lodash';





export const TimeslotsApi = createApi({
  reducerPath: 'TimeslotsApi',
  tagTypes: ['Timeslots'],
  baseQuery: fakeBaseQuery(),
  endpoints: builder => ({
    listTimeslots: builder.query({
      queryFn: async (arg, queryApi, _extraOptions, _baseQuery) => {
        const { dispatch, extra: sdk } = queryApi; // eslint-disable-line no-unused-vars
        const params = {
          ...arg,
        };

        const { start, end } = params;

        const sequence = getDateRanges(start, end);

        const queries = sequence.map(datesPara => sdk.timeslots.query({ start: datesPara.start, end: datesPara.end, listingId: params.listingId }));

        const results = await Promise.all(queries);

        const meta = { totalItems: 0, totalPages: 1, page: 1, perPage: 700 };

        const allTimeslots = results.flatMap((timeslotsResponse) => {

          const { data: timeslots, meta: pagination } = timeslotsResponse.data;

          meta.totalItems += pagination.totalItems;


          return timeslots;

        });

        const data = {
          timeslots: allTimeslots, pagination: meta
        };

        /* const timeslotsResponse = await sdk.timeslots.query(params);
           const { data: timeslots, meta: pagination } = timeslotsResponse.data;
           const data = {
             timeslots,
             pagination,
           }; */

        return { data };
      },
      providesTags: (result, error, arg) =>
        result
          ? [...result.timeslots.map(({ id }) => ({ type: 'Timeslots', id: id.uuid })), 'Timeslots']
          : ['Timeslots'],
    }),
  }),
});

export const { useListTimeslotsQuery, reducer } = TimeslotsApi;
