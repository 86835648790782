import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldTextInput, FieldSelect, PrimaryButton } from '..';
import getCountryCodes, { getDeliverableCountryCodes } from '../../translations/countryCodes';
import { required } from '../../util/validators';
import css from './ProfileAddress.module.css';

function Step3(props) {
  const intl = useIntl();
  const t = intl.formatMessage;
  const { fieldId, submitInProgress, submitDisabled, pristineSinceLastSubmit } = props;
  return (
    <div>
      <FieldTextInput
        className={css.field}
        type="text"
        id={`${fieldId}.contactPersonOnSite`}
        name={`${fieldId}.contactPersonOnSite`}
        label={t({
          id: 'ProfileAddress.contactPersonOnSiteLabel',
        })}
        placeholder={t({
          id: 'ProfileAddress.contactPersonOnSitePlaceholder',
        })}
        validate={required(
          t({
            id: 'ProfileAddress.contactPersonOnSiteRequired',
          })
        )}
        customErrorClass={css.field}
      />
      <FieldTextInput
        className={css.field}
        type="text"
        id={`${fieldId}.contactPhoneOnSite`}
        name={`${fieldId}.contactPhoneOnSite`}
        label={t({
          id: 'ProfileAddress.contactPhoneOnSiteLabel',
        })}
        placeholder={t({
          id: 'ProfileAddress.contactPhoneOnSitePlaceholder',
        })}
        validate={required(
          t({
            id: 'ProfileAddress.contactPhoneOnSiteRequired',
          })
        )}
        customErrorClass={css.fieldError}
      />
      <FieldTextInput
        className={css.field}
        type="text"
        id={`${fieldId}.gpsCoordinates`}
        name={`${fieldId}.gpsCoordinates`}
        label={t({
          id: 'ProfileAddress.gpsCoordinatesLabel',
        })}
        placeholder={t({
          id: 'ProfileAddress.gpsCoordinatesPlaceholder',
        })}
        customErrorClass={css.fieldError}
      />

      <FieldTextInput
        className={css.field}
        type="textarea"
        id={`${fieldId}.additionalInfo`}
        name={`${fieldId}.additionalInfo`}
        label={t({
          id: 'ProfileAddress.additionalInfoLabel',
        })}
        placeholder={t({
          id: 'ProfileAddress.additionalInfoPlaceholder',
        })}
        // validate={required(
        //   t({
        //     id: 'ProfileAddress.additionalInfoRequired',
        //   })
        // )}
        customErrorClass={css.fieldError}
      />
      <div className={css.buttonSegment}>
        <PrimaryButton
          className={css.backBtn}
          type="button"
          disabled={props.currentStep === 1}
          onClick={props.previousStep}
        >
          <FormattedMessage id="ProfileAddress.Wizard.Back" />
        </PrimaryButton>
        {props.currentStep === 3 ? (
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={pristineSinceLastSubmit}
          >
            <FormattedMessage id="ProfileAddress.saveChanges" />
          </PrimaryButton>
        ) : (
          <PrimaryButton
            className={css.nextBtn}
            type="button"
            disabled={props.currentStep === 3}
            onClick={props.nextStep}
          >
            Next
          </PrimaryButton>
        )}
      </div>
    </div>
  );
}

export default Step3;
