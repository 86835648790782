import {getCountryCodes,getDeliverableCountryCodes} from '../translations/countryCodes';

/////////////////////////////////////////////////////////
// Configurations related to user.                     //
/////////////////////////////////////////////////////////

// Note: The userFields come from userFields asset nowadays by default.
//       To use this built-in configuration, you need to change the overwrite from configHelper.js
//       (E.g. use mergeDefaultTypesAndFieldsForDebugging func)

/**
 * Configuration options for user fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public', 'protected', or 'private'.
 *                                  Default value: 'public'.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs on
 *                                  ProfileSettingsPage and AuthenticationPage.
 * - showConfig:                    Configuration for rendering user information. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - displayInProfile (optional):   Can be used to hide field content from profile page.
 *                                    Default value: true.
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for users to fill
 *   - requiredMessage (optional):    Message for mandatory fields.
 *   - displayInSignUp (optional):    Can be used to show field input on sign up page.
 *                                    Default value: true.
 * - userTypeConfig:                Configuration for limiting user field to specific user types.
 *   - limitToUserTypeIds:            Can be used to determine whether to limit the field to certain user types. The
 *                                    Console based asset configurations do not yet support user types, so in hosted configurations
 *                                    the default value for this is 'false'.
 *   - userTypeIds:                   An array of user types for which the extended
 *   (optional)                       data is relevant and should be added.
 */
export const userFields = [
  {
    key: 'salutation',
    scope: 'protected',
    schemaType: 'enum',
    enumOptions: [{ option: 'mr', label: 'Mr.' }, { option: 'ms', label: 'Ms.' }],
    showConfig: {
      label: 'Salutation',
      scopes: {
        tenant: 'protected',
        lessor: 'public',
      },
    },
    saveConfig: {
      label: 'Salutation',
      displayInSignUp: true,
      isRequired: true,
    },
    userTypeConfig: {
      limitToUserTypeIds: true,
      userTypeIds: ['tenant', 'lessor'],
      scopes: {
        tenant: 'protected',
        lessor: 'public',
      },
    },
  },
  {
    key: 'addressStreet',
    scope: 'protected',
    schemaType: 'text',
    showConfig: {
      label: 'Street',
    },
    saveConfig: {
      label: 'Street',
      displayInSignUp: true,
      isRequired: true,
    },
    userTypeConfig: {
      limitToUserTypeIds: true,
      userTypeIds: ['tenant', 'lessor'],
      scopes: {
        tenant: 'protected',
        lessor: 'public',
      },
    },
  },
  {
    key: 'addressStreetNumber',
    scope: 'protected',
    schemaType: 'text',
    showConfig: {
      label: 'Street No',
    },
    saveConfig: {
      label: 'Street No',
      displayInSignUp: true,
      isRequired: true,
    },
    userTypeConfig: {
      limitToUserTypeIds: true,
      userTypeIds: ['tenant', 'lessor'],
      scopes: {
        tenant: 'protected',
        lessor: 'public',
      },
    },
  },
  {
    key: 'addressPostalcode',
    scope: 'protected',
    schemaType: 'text',
    showConfig: {
      label: 'Postalcode',
    },
    saveConfig: {
      label: 'Postalcode',
      displayInSignUp: true,
      isRequired: true,
    },
    userTypeConfig: {
      limitToUserTypeIds: true,
      userTypeIds: ['tenant', 'lessor'],
      scopes: {
        tenant: 'protected',
        lessor: 'public',
      },
    },
  },
  {
    key: 'addressCity',
    scope: 'protected',
    schemaType: 'text',
    showConfig: {
      label: 'City',
    },
    saveConfig: {
      label: 'City',
      displayInSignUp: true,
      isRequired: true,
    },
    userTypeConfig: {
      limitToUserTypeIds: true,
      userTypeIds: ['tenant', 'lessor'],
      scopes: {
        tenant: 'protected',
        lessor: 'public',
      },
    },
  },
  {
    key: 'addressCountry',
    scope: 'protected',
    schemaType: 'enum',
    enumOptions: getDeliverableCountryCodes('de').map(item => ({ option: item.code, label: item.name })),
    showConfig: {
      label: 'Country',
    },
    saveConfig: {
      label: 'Country',
      displayInSignUp: true,
      isRequired: true,
    },
    userTypeConfig: {
      limitToUserTypeIds: true,
      userTypeIds: ['tenant', 'lessor'],
      scopes: {
        tenant: 'protected',
        lessor: 'public',
      },
    },
  },
/*   {
    key: 'workingHours',
    scope: 'protected',
    schemaType: 'text',
    showConfig: {
      label: 'Working Hours',
    },
    saveConfig: {
      label: 'Working Hours',
      isRequired: false,
    },
    userTypeConfig: {
      limitToUserTypeIds: true,
      userTypeIds: ['lessor'],
      scopes: {
        tenant: 'protected',
        lessor: 'public',
      },
    },
  }, */
];

/////////////////////////////////////
// Example user type configuration //
/////////////////////////////////////
/**
 * User types are not supported in hosted configuration yet.
 *
 * To take user types into use in your
 * custom code, you can do the following things:
 * - Add a new user field with key 'userType', scope 'publicData', and schemaType enum
 *  - Consider whether or not you want to allow your users to change their user type after first creating it
 * - Set your user types as the available options for the userType field
 * - Add your user types in the array below
 * - Update configHelpers.js mergeUserConfig to pass user types to the validUserFields function
 */

export const userTypes = [
  {
    userType: 'tenant',
    label: 'Tenant',
    displayNameSettings: {
      displayInSignUp: true,
      required: true,
    },
    phoneNumberSettings: {
      displayInSignUp: true,
      required: true,
    },
  },
  {
    userType: 'lessor',
    label: 'Lessor',
    displayNameSettings: {
      displayInSignUp: true,
      required: true,
    },
    phoneNumberSettings: {
      displayInSignUp: true,
      required: true,
    },
  },
];
