import React from 'react';
import { oneOf, string } from 'prop-types';

import { useConfiguration } from '../../context/configurationContext';

import LogoIcon from './LogoIcon';
import css from './Logo.module.css';

export const LogoComponent = props => {
  const {
    className,
    logoImageClassName,
    layout,
    marketplaceName,
    logoImageDesktop,
    logoImageMobile,
    logoSettings,
    ...rest
  } = props;

  const logoClasses = className || css.root;

  if (layout === 'desktop') {
    return (
      <div className={logoClasses}>
        <LogoIcon className={css.logoSVG} alt={marketplaceName} {...rest} />
      </div>
    );
  }

  return (
    <div className={logoClasses}>
      <LogoIcon className={css.logoSVG} alt={marketplaceName} {...rest} />
    </div>
  );
};

const Logo = props => {
  const config = useConfiguration();
  // NOTE: logo images are set in hosted branding.json asset or src/config/brandingConfig.js
  const { logoImageDesktop, logoImageMobile, logoSettings } = config.branding;

  return (
    <LogoComponent
      {...props}
      logoImageDesktop={logoImageDesktop}
      logoImageMobile={logoImageMobile}
      logoSettings={logoSettings}
      marketplaceName={config.marketplaceName}
    />
  );
};

Logo.defaultProps = {
  className: null,
  layout: 'desktop',
};

Logo.propTypes = {
  className: string,
  layout: oneOf(['desktop', 'mobile']),
};

export default Logo;
