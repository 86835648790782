import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { currentUserShowSuccess } from './user.duck';
import { denormalisedResponseEntities, USER_AVATAR_IMAGE_QUERY_PARAMS } from '../util/data';

export const UserApi = createApi({
  reducerPath: 'UserApi',
  tagTypes: ['UserProfile'],
  baseQuery: fakeBaseQuery(),
  endpoints: builder => ({
    updateProfile: builder.mutation({
      queryFn: async (arg, queryApi, _extraOptions, _baseQuery) => {
        const { dispatch, extra: sdk } = queryApi;
        const updateValues = { ...arg };
        const queryParams = {
          expand: true,
          include: ['profileImage'],
          ...USER_AVATAR_IMAGE_QUERY_PARAMS,
        };
        const updateProfileResponse = await sdk.currentUser.updateProfile(
          updateValues,
          queryParams
        );
        const entities = denormalisedResponseEntities(updateProfileResponse);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];
        dispatch(currentUserShowSuccess(currentUser));

        const data = currentUser;
        return { data };
      },
      invalidatesTags: ['UserProfile'],
    }),
    updateProfileWithoutReload: builder.mutation({
      queryFn: async (arg, queryApi, _extraOptions, _baseQuery) => {
        const { extra: sdk } = queryApi;
        const updateValues = { ...arg };
        const queryParams = {
          expand: true,
        };
        const updateProfileResponse = await sdk.currentUser.updateProfile(
          updateValues,
          queryParams
        );
        const entities = denormalisedResponseEntities(updateProfileResponse);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];
        const data = currentUser;
        return { data };
      },
    }),
  }),
});

export const { useUpdateProfileMutation, useUpdateProfileWithoutReloadMutation, reducer } = UserApi;
